import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide-menu-stations',
  templateUrl: './slide-menu-stations.component.html',
  styleUrls: ['./slide-menu-stations.component.css']
})
export class SlideMenuStationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
