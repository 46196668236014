<!-- <head>
    <title>Mapa • Gas Mex</title> -->

    <!-- <meta property="og:url" content="{{href}}" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="Gas Mex" />
    <meta property="og:description" content="Precios de todas las gasolineras de México en tiempo real" />
    <meta property="og:image" content="{{href}}assets/img/GASMEXVIP.png" /> -->
<!-- </head> -->

<div class="container-fluid">
    <div class="row">
        <div 
            class="col-12 col-md-4 col-lg-4 col-xl-3 d-none d-md-block pt-4" 
            style="overflow-y: auto;"
            [ngStyle]="{'max-height.px': outerHeight}"
        >
            <div class="row" *ngIf="loading">
                <app-slide-menu-stations></app-slide-menu-stations>
            </div>
            <div class="row" *ngIf="!loading">
                <a (click)="moveMap(station)" class="col-12 mb-4 pb-4 border-bottom text-decoration-none" *ngFor="let station of stations">
                    <div class="row">
                        <div class="col-12 mb-2 text-black">
                            <b>{{station.name}}</b>
                        </div>
                        <div class="col-12 text-secondary mb-3">
                            {{station.cre_id}}
                        </div>
                        <div class="col-12 d-flex justify-content-around mb-4">
                            <div class="" *ngFor="let price of station.prices">
                                <div class="text-center text-white px-3 py-1 rounded-top" [ngClass]="{ 'regular-header': price.type == 'regular', 'premium-header': price.type == 'premium', 'diesel-header': price.type == 'diesel' }">
                                    <small>{{price.type}}</small>
                                </div>
                                <div class="text-center text-white px-3 py-1 rounded-bottom" [ngClass]="{ 'regular-content': price.type == 'regular', 'premium-content': price.type == 'premium', 'diesel-content': price.type == 'diesel' }">
                                    $ {{price.price.toFixed(2)}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 text-secondary d-flex justify-content-between">
                            <a [routerLink]="['/stations', station.id_place]">Ver más...</a>
                            <small>A {{station.distance}} Km de ti</small>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-12 col-md-8 col-lg-8 col-xl-9 p-0">
            <div 
                *ngIf="apiLoaded | async" 
                style="width: 100%"
                [ngStyle]="{'height.px': outerHeight}"
            >
                <google-map
                    height="100%"
                    width="100%"
                    [center]="center"
                    [zoom]="zoom"
                    [options]="optionsMap"
                    (mapDragend)="getStations()"
                    (mapInitialized)="onMapReady($event)"
                >
                    <map-marker #marker="mapMarker"
                        *ngFor="let station of stations"
                        [position]="stationService.getLatLng(station)"
                        [options]="stationService.getMarkerOptions(station)"
                        (mapClick)="openInfoWindow(marker, station)"
                    ></map-marker>
                    <map-info-window>
                        <div  *ngIf="stationSelected != null">
                            <b>{{stationSelected!.name}}</b>
                            <br>
                            {{stationSelected!.cre_id}}
                            <br><br>
                            <div class="col-12 mb-3 d-flex justify-content-center">
                                <div class="mx-1" *ngFor="let price of stationSelected.prices">
                                    <div class="text-center text-white px-3 py-1 rounded-top" [ngClass]="{ 'regular-header': price.type == 'regular', 'premium-header': price.type == 'premium', 'diesel-header': price.type == 'diesel' }">
                                        <small>{{price.type}}</small>
                                    </div>
                                    <div class="text-center text-white px-3 py-1 rounded-bottom" [ngClass]="{ 'regular-content': price.type == 'regular', 'premium-content': price.type == 'premium', 'diesel-content': price.type == 'diesel' }">
                                        $ {{price.price.toFixed(2)}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-end text-secondary">
                                <small>A {{stationSelected!.distance}} Km de ti</small>
                            </div>
                            <br>
                            <a [routerLink]="['/stations', stationSelected!.id_place]">Ver más...</a>
                        </div>
                    </map-info-window>
                </google-map>
            </div>
        </div>
    </div>
</div>