<div class="container-fluid p-0">
    <div class="row">
        <div class="col-12 mb-4 border-bottom pb-4" *ngFor="let f of [].constructor(6)">
            <div class="row">
                <div class="col-12">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </div>
                
                <div class="col-12 mb-3">
                    <ngx-skeleton-loader [theme]="{'width': '20vw'}">
                    </ngx-skeleton-loader>
                </div>
                
                <div class="col-12 mb-3">
                    <div class="row">
                        <div class="col-4">
                            <div class="row">
                                <div class="col-10 mx-auto">
                                    <ngx-skeleton-loader [theme]="{'height': '6vh'}">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                
                        <div class="col-4">
                            <div class="row">
                                <div class="col-10 mx-auto">
                                    <ngx-skeleton-loader [theme]="{'height': '6vh'}">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                
                        <div class="col-4">
                            <div class="row">
                                <div class="col-10 mx-auto">
                                    <ngx-skeleton-loader [theme]="{'height': '6vh'}">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-12 d-flex justify-content-between">
                    <ngx-skeleton-loader [theme]="{'width': '6vw'}">
                    </ngx-skeleton-loader>

                    <ngx-skeleton-loader [theme]="{'width': '6vw'}">
                    </ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</div>