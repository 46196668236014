import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {

  public domain: string = '';

  constructor(
    @Inject(DOCUMENT) private document: any
  ) { }

  ngOnInit(): void {
    this.domain = this.document.location.hostname;
    console.log(this.domain);
  }

}
