import { Component, OnInit, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'web-gasmex';

  public showPolicyCookies: boolean = false;

  constructor(
    private router: Router,
  ){
    let status = localStorage.getItem('showPolicyCookies');

    if(status == null){
      // console.log('no existe');
      this.showPolicyCookies = true;
    }

    else{
      this.showPolicyCookies = status == 'true' ? true : false;
    }
  }

  ngOnInit(): void {
    if(!isDevMode()){
      this.setUpAnalytics();
    }
  }

  setShowPolicyCookies(status: boolean){
    this.showPolicyCookies = status;
    localStorage.setItem('showPolicyCookies', status.toString());
  }

  setUpAnalytics() {
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    //   .subscribe((event) => {
    //       gtag('config', 'G-YOUR-GOOGLE-ID',
    //           {
    //               page_path: event.urlAfterRedirects
    //           }
    //       );
    //   }
    // );

    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
          gtag(
            'config', 'G-KSLZWS6H56', 
            {
              'page_path': event.urlAfterRedirects
            }
          );
      }
    });
  }
}
