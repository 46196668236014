import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private http: HttpClient
  ) { }

  public recordCoordinates(lat: number, lng: number): Observable<any>{
    return this.http.post(
      `${environment.gasMexApi}/analytics/coordinates`,
      JSON.stringify({
        lat: lat,
        lng: lng
      })
    );
  }

  public recordStationsDisplayed(stations: Array<number>): Observable<any>{
    return this.http.post(
      `${environment.gasMexApi}/analytics/displayed/stations`,
      JSON.stringify({
        stations: stations,
      })
    );
  }
}
