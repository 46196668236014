<head>
    <title>Descargar • Gas Mex</title>
</head>
<app-header></app-header>
<div class="container-fluid py-5">

    <div class="row">
        
        <div class="col-12">
            <div class="container">
                <div class="row">
    
                    <div class="col-12 col-md-6 d-flex align-items-center order-2 order-md-1">
                        <div class="row">
            
                            <div 
                                class="col-12 mb-2"
                                style="font-weight: 800; font-size: 30px !important"
                            >
                                La mejor app para poder ahorrar gasolina
                            </div>
            
                            <div class="col-12 mb-5 text-secondary">
                                Con GasMex podrás ver todo los precios de la gasolina en todo México. Totalmente gratis
                            </div>
            
                            <div class="col-12 d-flex pb-5">
            
                                <a href={{env.googlePlay}} target="_blank" class="bg-black text-white px-4 py-2 rounded-pill align-items-center d-flex order-1 order-md-0 text-decoration-none mx-1">
                                    <div class="bg-red me-3" style="width: calc(0.8em + 0.8vh);">
                                        <img class="" src="./assets/img/google-play-logo.png" width="100%">
                                    </div>
            
                                    <div class="d-flex flex-column">
                                        <div class="" style="font-size: calc(0.4em + 0.4vh); font-weight: 300">
                                            Disponible en
                                        </div>
            
                                        <div class="">
                                            Google Play
                                        </div>
                                    </div>
                                </a>
            
                                <a href={{env.appStore}} target="_blank" class="bg-black text-white px-4 py-2 rounded-pill align-items-center d-flex mr-4 order-0 order-md-1 text-decoration-none mx-1">
            
                                    <div class="bg-red me-3" style="width: calc(0.8em + 0.8vh);">
                                        <img class="" src="./assets/img/apple-logo-black.png" width="100%">
                                    </div>
            
                                    <div class="d-flex flex-column">
                                        <div class="" style="font-size: calc(0.4em + 0.4vh); font-weight: 300">
                                            Disponible en
                                        </div>
            
                                        <div class="">
                                            App Store
                                        </div>
                                    </div>
                                </a>
            
                            </div>
            
                        </div>
                    </div>
            
                    <div class="col-12 col-md-6 py-5 text-center order-1 order-md-2">
                        <img src="./assets/img/screenshots/IMG_6703_iphone13promaxgold_portrait.png" width="60%">
                    </div>
    
                </div>
            </div>
        </div>

        <div class="col-12 bg-secondary py-5">

        </div>

    </div>

</div>
<app-footer></app-footer>
