import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import * as moment from 'moment';

import { environment } from 'src/environments/environment';

//ANCHOR SERVICES
import { StationService } from 'src/app/services/station/station.service';

// ANCHOR MODELS
import { Station, IStation } from 'src/app/models/station.model';
import { HistoricalPrice } from 'src/app/models/historical-price.model';

@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.css']
})
export class StationComponent implements OnInit {

  public differenceRegular: number = 0;
  public differencePremium: number = 0;
  public differenceDiesel: number = 0;
  public station?: Station;
  public href: string = window.location.href;
  public loading = true;
  public outerHeight: number = document.documentElement.clientHeight;
  public classDiv = '';
  public env = environment;

  public moment = moment;

  public regularPricesThisWeek: Array<HistoricalPrice> = [];
  public premiumPricesThisWeek: Array<HistoricalPrice> = [];
  public dieselPricesThisWeek: Array<HistoricalPrice> = []; 

  public lineChartDataRegular?: ChartConfiguration['data'];
  public lineChartDataPremium?: ChartConfiguration['data'];
  public lineChartDataDiesel?: ChartConfiguration['data'];
    // datasets: [
    //   {
    //     data: [ 65, 59, 80, 81, 56, 55, 40 ],
    //     label: 'Regular',
    //     backgroundColor: 'rgba(67, 160, 72, 0.2)',
    //     borderColor: '#2E7D32',
    //     pointBackgroundColor: 'rgba(148,159,177,1)',
    //     pointBorderColor: '#fff',
    //     pointHoverBackgroundColor: '#fff',
    //     pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    //     fill: 'origin',
    //   },
    //   {
    //     data: [ 28, 48, 40, 19, 86, 27, 90 ],
    //     label: 'Series B',
    //     backgroundColor: 'rgba(77,83,96,0.2)',
    //     borderColor: 'rgba(77,83,96,1)',
    //     pointBackgroundColor: 'rgba(77,83,96,1)',
    //     pointBorderColor: '#fff',
    //     pointHoverBackgroundColor: '#fff',
    //     pointHoverBorderColor: 'rgba(77,83,96,1)',
    //     fill: 'origin',
    //     // pointStyle: 'dash'
    //     // stepped: true
    //     borderDash: [5,2]
    //   },
    //   {
    //     data: [ 180, 480, 770, 90, 1000, 270, 400 ],
    //     label: 'Series C',
    //     yAxisID: 'y-axis-1',
    //     backgroundColor: 'rgba(255,0,0,0.3)',
    //     borderColor: 'red',
    //     pointBackgroundColor: 'rgba(148,159,177,1)',
    //     pointBorderColor: '#fff',
    //     pointHoverBackgroundColor: '#fff',
    //     pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    //     fill: 'origin',
    //   }
    // ],
    // labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July' ]
  // };

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {
        grid: {
          color: 'white'
        }
      },
      y:{
        position: 'right',
      },
      // 'y-axis-1': {
      //   position: 'right',
      //   grid: {
      //     color: 'rgba(255,0,0,0.3)',
      //   },
      //   ticks: {
      //     color: 'black'
      //   }
      // }
    },

    plugins: {
      legend: { display: true },
      // annotation: {
      //   annotations: [
      //     {
      //       type: 'line',
      //       scaleID: 'x',
      //       value: 'March',
      //       borderColor: 'orange',
      //       borderWidth: 2,
      //       label: {
      //         position: 'center',
      //         enabled: true,
      //         color: 'orange',
      //         content: 'LineAnno',
      //         font: {
      //           weight: 'bold'
      //         }
      //       }
      //     },
      //   ],
      // }
    }
  };

  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  constructor(
    public currentRoute: ActivatedRoute,
    private router: Router,
    public stationService: StationService,
    private metaService: Meta,
    public titleService: Title,
  ) { 
    this.metaService.updateTag({
      name: 'Estación • Gas Mex'
    });

    this.titleService.setTitle('Estación • Gas Mex');
  }

  ngOnInit(): void {
    // console.log(moment.now());
    moment.locale('es');
    this.stationService.getStation(this.currentRoute.snapshot.params.id)
    .subscribe((data) => {
      console.log(data);
      this.station = data.data.station;
      // console.log(this.station);
      // console.log(this.station?.historicalPrices);
      this.metaService.updateTag({
        name: `${this.station!.name} • Gas Mex`
      });
  
      this.titleService.setTitle(`${this.station!.name} • Gas Mex`);

      this.getInsights();
      this.loading = false;
    },
    (error) => {
        console.log(error);
        this.router.navigate(['/station-not-found']);
    });
    this.outerHeight = document.documentElement.clientHeight - document.querySelector("footer")!.clientHeight - document.querySelector("header")!.clientHeight - 5;
  }

  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
  }

  getSnapshotMap(): string {
    return `https://maps.googleapis.com/maps/api/staticmap?center=${this.station!.latitude},${this.station!.longitude}&zoom=18&scale=2&style=feature:poi|visibility:off&markers=color:red%7C${this.station!.latitude},${this.station!.longitude}&size=1920x1080&key=${this.env.googleMapsKey}`
  }

  getInsights(){

    this.station!.historicalPrices.regular.thisWeek.forEach((historicalPrice) => {
      // if(this.regularPricesThisWeek.length == 0){
      //   this.regularPricesThisWeek.push(historicalPrice);
      // }

      // else{

      //   let maybeHP = this.regularPricesThisWeek.find((e) => {
      //     return e.weekDay == historicalPrice.weekDay
      //   });

      //   if( maybeHP != null){
      //     if(maybeHP.price < historicalPrice.price){
      //       let indexOfMHP = this.regularPricesThisWeek.findIndex((e) => {
      //         return e.weekDay == historicalPrice.weekDay
      //       });

      //       this.regularPricesThisWeek[indexOfMHP] = historicalPrice;
      //     }
      //   }

      //   else{
      //     this.regularPricesThisWeek.push(historicalPrice);
      //   }
      // }

      this.regularPricesThisWeek.push(historicalPrice);
    });

    console.log(this.regularPricesThisWeek);

    this.station!.historicalPrices.premium.thisWeek.forEach((historicalPrice) => {
      // if(this.premiumPricesThisWeek.length == 0){
      //   this.premiumPricesThisWeek.push(historicalPrice);
      // }

      // else{

      //   let maybeHP = this.premiumPricesThisWeek.find((e) => {
      //     return e.weekDay == historicalPrice.weekDay
      //   });

      //   if( maybeHP != null){
      //     if(maybeHP.price < historicalPrice.price){
      //       let indexOfMHP = this.premiumPricesThisWeek.findIndex((e) => {
      //         return e.weekDay == historicalPrice.weekDay
      //       });

      //       this.premiumPricesThisWeek[indexOfMHP] = historicalPrice;
      //     }
      //   }

      //   else{
      //     this.premiumPricesThisWeek.push(historicalPrice);
      //   }
      // }

      this.premiumPricesThisWeek.push(historicalPrice);
    });

    console.log(this.premiumPricesThisWeek);

    this.station!.historicalPrices.diesel.thisWeek.forEach((historicalPrice) => {
      // if(this.dieselPricesThisWeek.length == 0){
      //   this.dieselPricesThisWeek.push(historicalPrice);
      // }

      // else{

      //   let maybeHP = this.dieselPricesThisWeek.find((e) => {
      //     return e.weekDay == historicalPrice.weekDay
      //   });

      //   if( maybeHP != null){
      //     if(maybeHP.price < historicalPrice.price){
      //       let indexOfMHP = this.dieselPricesThisWeek.findIndex((e) => {
      //         return e.weekDay == historicalPrice.weekDay
      //       });

      //       this.dieselPricesThisWeek[indexOfMHP] = historicalPrice;
      //     }
      //   }

      //   else{
      //     this.dieselPricesThisWeek.push(historicalPrice);
      //   }
      // }

      this.dieselPricesThisWeek.push(historicalPrice);
    });

    console.log(this.dieselPricesThisWeek);

    this.regularPricesThisWeek.sort((a,b) => moment(a.lastUpdate).diff(moment(b.lastUpdate), 'days', true));
    this.premiumPricesThisWeek.sort((a,b) => moment(a.lastUpdate).diff(moment(b.lastUpdate), 'days', true));
    this.dieselPricesThisWeek.sort((a,b) => moment(a.lastUpdate).diff(moment(b.lastUpdate), 'days', true));

    var datasetsRegular: Array<any> = [];
    var datasetsPremium: Array<any> = [];
    var datasetsDiesel: Array<any> = [];

    if(this.regularPricesThisWeek.length > 0){

      let pricesSorted = [...this.regularPricesThisWeek];

      pricesSorted.sort((a,b) => a.price - b.price);

      // console.log(pricesSorted);

      this.differenceRegular = Number((1 - (pricesSorted[0].price / this.regularPricesThisWeek[this.regularPricesThisWeek.length - 1].price)).toFixed(3));
      
      datasetsRegular.push({
        data: this.regularPricesThisWeek.map((e) => e.price),
        label: 'Regular',
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        borderColor: '#2E7D32',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      });
    }

    if(this.premiumPricesThisWeek.length > 0) {

      let pricesSorted = [...this.premiumPricesThisWeek];

      pricesSorted.sort((a,b) => a.price - b.price);

      // console.log(pricesSorted);

      this.differencePremium = Number((1 - (pricesSorted[0].price / this.premiumPricesThisWeek[this.premiumPricesThisWeek.length - 1].price)).toFixed(3));

      datasetsPremium.push({
        data: this.premiumPricesThisWeek.map((e) => e.price),
        label: 'Premium',
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        borderColor: '#C62828',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      });
    }

    if(this.dieselPricesThisWeek.length > 0) {

      let pricesSorted = [...this.dieselPricesThisWeek];

      pricesSorted.sort((a,b) => a.price - b.price);

      // console.log(pricesSorted);

      this.differenceDiesel = Number((1 - (pricesSorted[0].price / this.dieselPricesThisWeek[this.dieselPricesThisWeek.length - 1].price)).toFixed(3));

      datasetsDiesel.push({
        data: this.dieselPricesThisWeek.map((e) => e.price),
        label: 'Diesel',
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        borderColor: '#424242',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      });
    }

    var labels = [];

    labels = this.regularPricesThisWeek.map((price, i) => {
      console.log(moment(price.lastUpdate).format("MM-DD-YYYY"));
      
      return moment(price.lastUpdate).format("DD MMM").toString();
    });

    this.lineChartDataRegular = {
      datasets: datasetsRegular,
      labels: labels
    };

    this.lineChartDataPremium = {
      datasets: datasetsPremium,
      labels: labels
    };

    this.lineChartDataDiesel = {
      datasets: datasetsDiesel,
      labels: labels
    };

    let lengths = [
      this.regularPricesThisWeek.length,
      this.premiumPricesThisWeek.length,
      this.dieselPricesThisWeek.length,
    ];

    let result = lengths.filter(e => e > 0);
    
    // console.log(result);

    switch(result.length){
      case 1:
        this.classDiv = 'col-12';
      break;

      case 2:
        this.classDiv = 'col-12 col-md-6';
      break;

      case 3:
        this.classDiv = 'col-12 col-md-4';
      break;

      default:
        this.classDiv = 'col-12 col-md-4';
      break;
    }

    // console.log(this.premiumPricesThisWeek[this.premiumPricesThisWeek.length - 3].price);
    
    
    // let difference = 1 - (this.regularPricesThisWeek[this.regularPricesThisWeek.length - 1].price / this.regularPricesThisWeek[this.regularPricesThisWeek.length - 3].price);
    let difference = 1 - (this.regularPricesThisWeek[this.regularPricesThisWeek.length - 2].price / this.regularPricesThisWeek[this.regularPricesThisWeek.length - 1].price);
    // let difference = 1 - (this.premiumPricesThisWeek[this.premiumPricesThisWeek.length - 3].price / this.premiumPricesThisWeek[this.premiumPricesThisWeek.length - 1].price);

    console.log(difference);

  }

}
