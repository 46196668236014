import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgChartsModule } from 'ng2-charts';

/* -------------------------------------------------------------------------- */
/*                               ANCHOR SERVICES                              */
/* -------------------------------------------------------------------------- */
import { StationService } from './services/station/station.service';
import { IPStackService } from './services/ipstack/ipstack.service';
import { LocalizationService } from './services/localization/localization.service';
import { AnalyticsService } from './services/analytics/analytics.service';
import { SlideMenuService } from './services/slide-menu/slide-menu.service';

/* -------------------------------------------------------------------------- */
/*                              ANCHOR COMPONENTS                             */
/* -------------------------------------------------------------------------- */
import { MapComponent } from './components/map/map.component';
import { CookiesComponent } from './components/policy/cookies/cookies.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { StationComponent } from './components/station/station/station.component';
import { SideStationsComponent } from './components/station/side-stations/side-stations.component';
import { SlideMenuStationsComponent } from './components/loaders/slide-menu-stations/slide-menu-stations.component';
import { StationNotFoundComponent } from './components/station-not-found/station-not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DownloadComponent } from './components/download/download.component';
import { SlideMenuComponent } from './components/slide-menu/slide-menu.component';
import { ShimmerStationComponent } from './components/shimmer/shimmer-station/shimmer-station.component';

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    CookiesComponent,
    PageNotFoundComponent,
    StationComponent,
    SideStationsComponent,
    SlideMenuStationsComponent,
    StationNotFoundComponent,
    HeaderComponent,
    FooterComponent,
    DownloadComponent,
    SlideMenuComponent,
    ShimmerStationComponent
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgxSkeletonLoaderModule,
    NgChartsModule,
  ],
  providers: [
    IPStackService,
    LocalizationService,
    StationService,
    AnalyticsService,
    SlideMenuService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
