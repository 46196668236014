<footer class="container-fluid" style="z-index: 1020">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-4 regular-header" style="height: 0.5vh">

                </div>

                <div class="col-4 premium-header" style="height: 0.5vh">

                </div>

                <div class="col-4 diesel-header" style="height: 0.5vh">

                </div>
            </div>
        </div>
        <div class="col-12 py-4">
            <div class="container">

                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-5 mb-md-0">
    
                        <div class="row">
                            <div class="col-12 mb-3">
                                <a [routerLink]="['/']">
                                    <img 
                                        src="./assets/img/GASMEXVIP_wobg.png"
                                        style="width: calc(3em + 3vh);"
                                    >
                                </a>
            
                                <a 
                                    class="text-decoration-none text-black" 
                                    [routerLink]="['/']"
                                >
                                    <b>Gas Mex</b>
                                </a>
                            </div>
    
                            <div class="col-12 d-flex px-4">
    
                                <a 
                                    href={{env.facebook}} 
                                    target="_blank"
                                    class="btn rounded-circle bg-black text-white me-3"
                                >
                                    <i class="fa-brands fa-facebook-f"></i>
                                </a>
    
                                <a 
                                    href={{env.instagram}} 
                                    target="_blank"
                                    class="btn rounded-circle bg-black text-white me-3"
                                >
                                    <i class="fa-brands fa-instagram"></i>
                                </a>
    
                                <a 
                                    href={{env.twitter}} 
                                    target="_blank"
                                    class="btn rounded-circle bg-black text-white me-3"
                                >
                                    <i class="fa-brands fa-twitter"></i>
                                </a>
                                
                            </div>
                        </div>
    
                    </div>
    
                    <div class="col-12 col-md-6 col-lg-4 mb-5 mb-md-0 px-4 px-md-0">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <b>Mapa del sitio</b>
                            </div>
                            <div class="col-12">
                                <a 
                                    [routerLink]="['/']" 
                                    class="text-decoration-none text-black"
                                >
                                    Mapa
                                </a>
                            </div>
    
                            <div class="col-12">
                                <a 
                                    [routerLink]="['/download']" 
                                    class="text-decoration-none text-black"
                                >
                                    Descargar
                                </a>
                            </div>
    
                            <div class="col-12">
                                <a 
                                    [routerLink]="['/privacy-policy']" 
                                    class="text-decoration-none text-black"
                                >
                                    Aviso de privacidad
                                </a>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-12 col-md-6 col-lg-4">
    
                        <div class="row">
                            <div class="col-12 d-flex">
    
                                <a href={{env.googlePlay}} target="_blank" class="bg-black text-white px-4 py-2 rounded-pill align-items-center d-flex order-1 order-md-0 text-decoration-none mx-1">
                                    <div class="bg-red me-3" style="width: calc(0.8em + 0.8vh);">
                                        <img class="" src="./assets/img/google-play-logo.png" width="100%">
                                    </div>
            
                                    <div class="d-flex flex-column">
                                        <div class="" style="font-size: calc(0.4em + 0.4vh); font-weight: 300">
                                            Disponible en
                                        </div>
            
                                        <div class="">
                                            Google Play
                                        </div>
                                    </div>
                                </a>
            
                                <a href={{env.appStore}} target="_blank" class="bg-black text-white px-4 py-2 rounded-pill align-items-center d-flex mr-4 order-0 order-md-1 text-decoration-none mx-1">
            
                                    <div class="bg-red me-3" style="width: calc(0.8em + 0.8vh);">
                                        <img class="" src="./assets/img/apple-logo-black.png" width="100%">
                                    </div>
            
                                    <div class="d-flex flex-column">
                                        <div class="" style="font-size: calc(0.4em + 0.4vh); font-weight: 300">
                                            Disponible en
                                        </div>
            
                                        <div class="">
                                            App Store
                                        </div>
                                    </div>
                                </a>
    
                            </div>
                        </div>
    
                    </div>
                </div>

            </div>
        </div>
        <div class="col-12 text-center py-2 text-white bg-grey-darken-4">
            Created by &lt;CradleCode/&gt;
        </div>
    </div>
</footer>