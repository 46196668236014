import { Price } from './price.model';
import { environment } from 'src/environments/environment';
import { HistoricalPrices } from './historical-prices.model'
import { Status } from './status.model';

export interface IStation {
    idStation: number;
    name: string;
    creId: string;
    longitude: number;
    latitude: number;
    lastUpdate: string;
    distance?: number;
    prices: Array<Price>;
    status: Status;
    historicalPrices?: HistoricalPrices;
    // getLatLng(): any;
}

export class Station implements IStation {
    public idStation: number;
    public name: string;
    public creId: string;
    public longitude: number;
    public latitude: number;
    public lastUpdate: string;
    public distance: number;
    public status: Status;
    public prices: Array<Price>;
    public historicalPrices: HistoricalPrices;

    constructor(
        idStation: number,
        name: string,
        creId: string,
        longitude: number,
        latitude: number,
        lastUpdate: string,
        distance: number,
        status: Status,
        prices: Array<Price>,
        historicalPrices: HistoricalPrices
    ){        
        this.idStation = idStation;
        this.name = name;
        this.creId = creId;
        this.longitude = longitude;
        this.latitude = latitude;
        this.lastUpdate = lastUpdate;
        this.distance = distance;
        this.status = status;
        this.prices = prices;
        this.historicalPrices = historicalPrices;
    }

}
