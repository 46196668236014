import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CookiesComponent } from './components/policy/cookies/cookies.component';
import { MapComponent } from './components/map/map.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { StationComponent } from './components/station/station/station.component';
import { StationNotFoundComponent } from './components/station-not-found/station-not-found.component';
import { DownloadComponent } from './components/download/download.component';

const routes: Routes = [
  { path: '', component: MapComponent },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: 'map', component: MapComponent },
  { path: 'map/:latlng', component: MapComponent },
  { path: 'download', component: DownloadComponent },
  { path: 'privacy-policy', component: CookiesComponent },
  { path: 'stations/:id', component: StationComponent },
  { path: 'station-not-found', component: StationNotFoundComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
