import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-station-not-found',
  templateUrl: './station-not-found.component.html',
  styleUrls: ['./station-not-found.component.css']
})
export class StationNotFoundComponent implements OnInit {

  constructor(
    private metaService: Meta,
    public titleService: Title,
  ) { 
    this.metaService.updateTag({
      name: 'Estación no encontrada • Gas Mex'
    });

    this.titleService.setTitle('Estación no encontrada • Gas Mex');
  }

  ngOnInit(): void {
  }

}
