import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';

//ANCHOR SERVICES
import { StationService } from 'src/app/services/station/station.service';
import { IPStackService } from 'src/app/services/ipstack/ipstack.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

// ANCHOR MODELS
import { Station, IStation } from 'src/app/models/station.model';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  // @ViewChild(GoogleMap, {static: false}) map?: GoogleMap
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  
  public map: GoogleMap | any ;

  public apiLoaded: Observable<boolean>;
  public env = environment;

  public center: google.maps.LatLngLiteral = {
    lat: 20.096888, 
    lng: -98.764734
  };
  public optionsMap: any = {
    fullscreenControl: false,
    streetViewControl: false,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP
    },
    mapTypeControlOptions: {
      position: google.maps.ControlPosition.LEFT_BOTTOM,
      // style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
    },
    styles: [
      {
        featureType: "poi",
        stylers: [
         { visibility: "off" }
        ]   
      }
    ]
  };
  public href: string = window.location.href;
  public loading: boolean = true;
  public outerHeight: number = document.documentElement.clientHeight;
  public stations: Array<any> = [];
  public stationSelected?: any;
  public zoom = 14;

  constructor(
    private analyticsService: AnalyticsService,
    httpClient: HttpClient,
    private location: Location,
    public stationService: StationService,
    public ipStackService: IPStackService,
    private router: Router,
    private metaService: Meta,
    public titleService: Title,
  ) {

    this.metaService.updateTag({
      name: 'Mapa • Gas Mex'
    });

    this.titleService.setTitle('Mapa • Gas Mex');

    this.apiLoaded = httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${this.env.googleMapsKey}`, 'callback')
        .pipe(
          map(() => {
            // this.getCurrentLocation();
            return true;
          }),
          catchError(() => of(false)),
        );
  }

  ngOnInit(): void {
    // console.log(`${window.location.href}`);
    // console.log('Noo aparece url');
    
  }

  // calculateDistance(lat1, lon1, lat2, lon2){
  //   var p = 0.017453292519943295;
  //   var c = cos;
  //   var a = 0.5 - c((lat2 - lat1) * p)/2 + 
  //         c(lat1 * p) * c(lat2 * p) * 
  //         (1 - c((lon2 - lon1) * p))/2;
  //   return 12742 * asin(sqrt(a));
  // }
  
  /* -------------------------------------------------------------------------- */
  /*                         FUNCTION calculateDistance                         */
  /* -------------------------------------------------------------------------- */

  calculateDistance(lat1: number, lng1: number, lat2: number, lng2: number): number {
    // console.info(Math.cos(lat2));
    let p = 0.017453292519943295;
    let a = 0.5 - Math.cos((lat2 - lat1) *  p) / 2 + Math.cos(lat1 * p) * Math.cos(lat2 * p) * (1 - Math.cos((lng2 - lng1) * p)) / 2;
    // console.info(a);
    return 12742 * Math.asin(Math.sqrt(a));
  }

  firstGetStations(){
    this.loading = true;
    let map = this.map as google.maps.Map;
    console.log(this.map.getCenter()!.toJSON());
    // console.log(map.getBounds()!.getSouthWest().lng());

    this.location.replaceState(`map/@${this.map.getCenter()!.lat()},${this.map.getCenter()!.lng()},${this.map.getZoom()!.toFixed(0)}`);

    this.analyticsService.recordCoordinates(
      map.getCenter()!.lat(),
      map.getCenter()!.lng(),
    ).subscribe((data) => {
      // console.log(data);
      
    });
    
    let distance = this.calculateDistance(
      map.getCenter()!.lat(),
      map.getCenter()!.lng(),
      map.getBounds()!.getSouthWest().lat(),
      map.getBounds()!.getSouthWest().lng()
    );
    
    // console.log(distance);

    this.stationService.getStations(
      this.center.lat,
      this.center.lng,
      Math.ceil(distance)
    ).subscribe((data) => {
      
      // console.log(data.places);
      
      this.stations = data.places
      console.log(this.stations);
      this.loading = false;

      this.analyticsService.recordStationsDisplayed(
        this.stations.map((station) => station.id_place)
      ).subscribe((data) => {
        console.log(data);
        
      });

    })
    
    console.log('Hello mother fucker');
    
  }

  /* -------------------------------------------------------------------------- */
  /*                         FUNCTION getCurrentLocation                        */
  /* -------------------------------------------------------------------------- */

  getCurrentLocation(flag: boolean = false){
    this.loading = true;
    navigator.geolocation.getCurrentPosition((position) => {
          // alert('Location accessed')
      console.log(position);
      this.center = {
        lat: position.coords.latitude, 
        lng: position.coords.longitude
      }

      if(flag){
        this.firstGetStations();
      }

      else{
        this.getStations();
      }
      
    },async () => {
        // alert('User not allowed')

        this.center = await this.ipStackService.getCurrentLocation();
        console.log(this.center);
        if(flag){
          this.firstGetStations();
        }
  
        else{
          this.getStations();
        }
    },{timeout:10000})
  }

  /* -------------------------------------------------------------------------- */
  /*                            FUNCTION getStations                            */
  /* -------------------------------------------------------------------------- */

  getStations(){
    this.loading = true;
    let map = this.map as google.maps.Map;
    // map.getZoom()!.toFixed(2)
    console.log(this.map.getCenter()!.toJSON());
    // console.log(map.getBounds()!.getSouthWest().lng());

    this.location.replaceState(`map/@${this.map.getCenter()!.lat()},${this.map.getCenter()!.lng()},${this.map.getZoom()!.toFixed(0)}`);

    this.analyticsService.recordCoordinates(
      this.map.getCenter()!.lat(),
      this.map.getCenter()!.lng(),
    ).subscribe((data) => {
      // console.log(data);
      
    });
    
    let distance = this.calculateDistance(
      this.map.getCenter()!.lat(),
      this.map.getCenter()!.lng(),
      this.map.getBounds()!.getSouthWest().lat(),
      this.map.getBounds()!.getSouthWest().lng()
    );
    
    // console.log(distance);

    this.stationService.getStations(
      this.map.getCenter()!.lat(),
      this.map.getCenter()!.lng(),
      Math.ceil(distance)
    ).subscribe((data) => {
      
      // console.log(data.places);
      
      this.stations = data.places;
      this.loading = false;
      console.log(this.stations);

      this.analyticsService.recordStationsDisplayed(
        this.stations.map((station) => station.id_place)
      ).subscribe((data) => {
        console.log(data);
        
      });
      
    })
    
    console.log('Hello mother fucker');
    
  }

  /* -------------------------------------------------------------------------- */
  /*                        FUNCTION onMapReadyonMapReady                       */
  /* -------------------------------------------------------------------------- */

  onMapReady(map: google.maps.Map): void {
    this.map = map;
    this.center = {
      lat: this.map.getCenter()!.lat(),
      lng: this.map.getCenter()!.lng(),
    }
    console.log('Mapa listo');
    console.log(this.map.getCenter()!.toJSON());
    
    
    this.getCurrentLocation(true);
  }

  /* -------------------------------------------------------------------------- */
  /*                           FUNCTION openInfoWindow                          */
  /* -------------------------------------------------------------------------- */

  openInfoWindow(marker: MapMarker, station: Station) {
    this.stationSelected = station;
    this.infoWindow!.open(marker);
  }

  moveMap(station: Station) {
    this.center = {
      lat: station.latitude, 
      lng: station.longitude
    };

    this.stationSelected = station;
    // this.infoWindow!.open(marker);

    this.zoom = 18
  }

}
