<router-outlet></router-outlet>
<div *ngIf="showPolicyCookies" class="py-4 position-fixed bottom-0 end-0 container-fluid text-white" style="z-index: 100; background-color: #00000087;">
  <div class="row">
    <div class="col-12">
      <h2><b>Cookies</b></h2>
    </div>
    <div class="col-12 mb-3">
      Nosotros y terceros seleccionados utilizamos cookies o tecnologías similares con fines técnicos y, con su consentimiento, para otras finalidades según se especifica en la <a>política de cookies</a>.
      Al seguir navegando en este sitio, usted esta aceptando dichas políticas.
      En gasmexapp.com usamos cookies para asegurar la mejor experiencia en nuestra web.
    </div>
    <div class="col-12">
      <button (click)="setShowPolicyCookies(false)" type="button" class="btn btn-primary rounded-pill me-5">Aceptar</button>
      <a [routerLink]="['/privacy-policy']" class="btn btn-light rounded-pill">Politica de cookies</a>
    </div>
  </div>
</div>
