<header class="container">
    <div class="row">
        <div class="col-12 d-block d-lg-none pt-2">
            <div class="row">
                <div class="col-4 d-flex align-items-center">
                    <button 
                        type="button" 
                        class="btn btn-link text-decoration-none text-black d-flex align-items-center"
                        (click)="slideMenuService.menuOpened = true"
                    >
                        <i class="material-icons">menu</i>
                    </button>
                </div>
                
                <div class="col-4 d-flex align-items-center justify-content-center">
                    <a [routerLink]="['/']">
                        <img 
                            src="./assets/img/GASMEXVIP_wobg.png"
                            style="width: calc(2em + 2vh);"
                        >
                    </a>

                    <a 
                        class="text-decoration-none text-black" 
                        [routerLink]="['/']"
                    >
                        <b>Gas Mex</b>
                    </a>
                </div>

                <div class="col-4">
                    
                </div>
            </div>
        </div>
        <div class="col-6 d-none d-lg-block">
            <div class="row">
                <div class="col-12 d-flex align-items-center">
                    <a [routerLink]="['/']">
                        <img 
                            src="./assets/img/GASMEXVIP_wobg.png"
                            style="width: calc(2em + 2vh);"
                        >
                    </a>

                    <a 
                        class="text-decoration-none text-black" 
                        [routerLink]="['/']"
                    >
                        <b>Gas Mex</b>
                    </a>
                </div>
            </div>
        </div>

        <div class="col-6 d-none d-lg-block">
            <div class="row h-100">
                <div class="col-12 h-100 d-flex align-items-center justify-content-end">
                    <!-- <a class="btn" [routerLink]="['/']">
                        Contacto
                    </a> -->
                    <a class="btn" [routerLink]="['/']">
                        Mapa
                    </a>

                    <a class="btn" [routerLink]="['/download']">
                        Descargar
                    </a>
                    
                </div>
            </div>
        </div>
    </div>
</header>
<app-slide-menu *ngIf="slideMenuService.menuOpened"></app-slide-menu>