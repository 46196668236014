import { Component, OnInit } from '@angular/core';

import { SlideMenuService } from 'src/app/services/slide-menu/slide-menu.service';

@Component({
  selector: 'app-slide-menu',
  templateUrl: './slide-menu.component.html',
  styleUrls: ['./slide-menu.component.css']
})
export class SlideMenuComponent implements OnInit {

  constructor(
    public slideMenuService: SlideMenuService
  ) { }

  ngOnInit(): void {
  }

}
