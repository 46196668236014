<!-- <head>
    <title>{{loading ? 'Cargando...' : station!.name}} • Gas Mex</title>
</head> -->
<app-header></app-header>
<div 
    class="container bg-white"
    [ngStyle]="{'min-height.px': outerHeight}"
>
    <div class="row pb-5">
        <!-- <div class="col-4 col-lg-1 d-none d-lg-block">
        </div> -->

        <div 
            class="col-12 bg-white"
            [ngStyle]="{'min-height.px': outerHeight}"
        >
            <div class="row pt-3" *ngIf="!loading">
                <div 
                    class="col-12 mb-3 bg-center rounded-4" 
                    style="height: 25vh"
                    [ngStyle]="{'background-image': 'url(' + getSnapshotMap() + ')'}"
                >

                </div>
                <div class="col-12 text-black">
                    <h4><b>{{station!.name}}</b></h4>
                </div>
                <div class="col-12 text-secondary mb-3">
                    {{station!.creId}}
                </div>
                <div class="col-12 d-flex justify-content-between mb-5">
                    <div 
                        class=""
                        [style.color]="station!.status.textColor"
                    >
                        {{station!.status.shortDescription}}
                    </div>

                    <div class="text-secondary">
                        Última actualización <b>{{ moment(station!.lastUpdate).fromNow() }}</b>
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-around mb-5 pb-5">
                    <div class="" *ngFor="let price of station!.prices">
                        <div class="text-center text-white px-3 py-1 rounded-top" [ngClass]="{ 'regular-header': price.type == 'regular', 'premium-header': price.type == 'premium', 'diesel-header': price.type == 'diesel' }">
                            <small>{{price.type}}</small>
                        </div>
                        <div class="text-center text-white px-4 py-1 rounded-bottom" [ngClass]="{ 'regular-content': price.type == 'regular', 'premium-content': price.type == 'premium', 'diesel-content': price.type == 'diesel' }">
                            $ {{price.price.toFixed(2)}}
                        </div>
                    </div>
                </div>

                <div 
                    class="mb-5"
                    [ngClass]="classDiv"
                    *ngIf="regularPricesThisWeek.length > 0"
                >
                    <div class="row">

                        <div 
                            class="col-12"
                            style="font-weight: 300;"
                        >
                            Regular
                        </div>
                        <div 
                            class="col-12"
                            style="font-size: calc(1.2em + 1.2vh)"
                        >
                            ${{regularPricesThisWeek[regularPricesThisWeek.length - 1].price.toFixed(2)}}
                        </div>
                        <div 
                            class="col-12"
                            style="font-size: calc(0.5em + 0.5vh)"
                            [ngClass]="{
                                'text-success' : differenceRegular < 0,
                                'text-danger': differenceRegular > 0 
                            }"
                        >
                            {{differenceRegular}}%
                        </div>

                        <div class="col-12">
                            <canvas 
                                baseChart width="100%" height="60vh"
                                [data]="lineChartDataRegular"
                                [options]="lineChartOptions"
                                [type]="lineChartType"
                                (chartHover)="chartHovered($event)"
                                (chartClick)="chartClicked($event)">
                            </canvas>
                        </div>
                    </div>
                </div>

                <div 
                    class="mb-5"
                    [ngClass]="classDiv"
                    *ngIf="premiumPricesThisWeek.length > 0"
                >
                    <div class="row">

                        <div 
                            class="col-12"
                            style="font-weight: 300;"
                        >
                            Premium
                        </div>
                        <div 
                            class="col-12"
                            style="font-size: calc(1.2em + 1.2vh)"
                        >
                            ${{premiumPricesThisWeek[premiumPricesThisWeek.length - 1].price.toFixed(2)}}
                        </div>
                        <div 
                            class="col-12"
                            style="font-size: calc(0.5em + 0.5vh)"
                            [ngClass]="{
                                'text-success' : differencePremium < 0,
                                'text-danger': differencePremium > 0 
                            }"
                        >
                            {{differencePremium}}%
                        </div>

                        <div class="col-12">
                            <canvas 
                                baseChart width="100%" height="60vh"
                                [data]="lineChartDataPremium"
                                [options]="lineChartOptions"
                                [type]="lineChartType"
                                (chartHover)="chartHovered($event)"
                                (chartClick)="chartClicked($event)">
                            </canvas>
                        </div>
                    </div>
                </div>

                <div 
                    class="mb-5"
                    [ngClass]="classDiv"
                    *ngIf="dieselPricesThisWeek.length > 0"
                >
                    <div class="row">

                        <div 
                            class="col-12"
                            style="font-weight: 300;"
                        >
                            Diesel
                        </div>
                        <div 
                            class="col-12"
                            style="font-size: calc(1.2em + 1.2vh)"
                        >
                            ${{dieselPricesThisWeek[dieselPricesThisWeek.length - 1].price.toFixed(2)}}
                        </div>
                        <div 
                            class="col-12"
                            style="font-size: calc(0.5em + 0.5vh)"
                            [ngClass]="{
                                'text-success' : differenceDiesel < 0,
                                'text-danger': differenceDiesel > 0 
                            }"
                        >
                            {{differenceDiesel}}%
                        </div>

                        <div class="col-12">
                            <canvas 
                                baseChart width="100%" height="60vh"
                                [data]="lineChartDataDiesel"
                                [options]="lineChartOptions"
                                [type]="lineChartType"
                                (chartHover)="chartHovered($event)"
                                (chartClick)="chartClicked($event)">
                            </canvas>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-12" style="height: 50vh"></div> -->
            </div>

            <div class="row" *ngIf="loading">
                <app-shimmer-station></app-shimmer-station>
            </div>

        </div>

        <!-- <div class="col-4 col-lg-1 d-none d-lg-block">
            
        </div> -->
    </div>
</div>
<app-footer></app-footer>