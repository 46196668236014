// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appStore: 'https://apps.apple.com/mx/app/gas-mex/id1554703179',
  facebook: 'https://www.facebook.com/gasmexapp',
  gasMexApi: 'https://gasmex.cradlecode.com.mx',
  googleMapsKey: 'AIzaSyBNgwFaOO6diXBA7lMmN7Xh2Yr-uI7thEs',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.cradlecode.gasmexflutter',
  instagram: 'https://www.instagram.com/gasmexapp/',
  IPStack: {
    api: 'http://api.ipstack.com',
    key: '0414350417d54bb36c4f134b52487c27',
  },
  twitter: 'https://twitter.com/gasmexapp',
  functions: {
    urlApi: 'https://us-central1-gasmex-dev.cloudfunctions.net/v0'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
