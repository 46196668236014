<div class="container">
    <div class="row pt-3">

        <div class="shimmer col-12 rounded-4 mb-3" style="height: 25vh"></div>

        <div class="col-12 mb-2">
            <div class="row">
                <div class="col-5 shimmer" style="height: 5vh"></div>
            </div>
        </div>

        <div class="col-12 mb-2">
            <div class="row">
                <div class="col-4 shimmer" style="height: 2.5vh"></div>
            </div>
        </div>

        <div class="col-12 mb-5">
            <div class="row">
                <div class="col-2 shimmer me-auto" style="height: 2.5vh"></div>
                <div class="col-4 shimmer" style="height: 2.5vh"></div>
            </div>
        </div>

        <div class="col-12 my-5 pb-5">
            <div class="row d-flex justify-content-around">
                <div class="col-2 shimmer" style="height: 8vh"></div>
                <div class="col-2 shimmer" style="height: 8vh"></div>
                <div class="col-2 shimmer" style="height: 8vh"></div>
            </div>
        </div>

        <div class="shimmer col-12 -4 mb-5" style="height: 25vh"></div>

    </div>
</div>