import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {

  public env = environment;
  public platform: string = '';
  public userAgent: string = '';

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log(navigator.platform);
    console.log(navigator);
    this.platform = navigator.platform;
    this.userAgent = navigator.userAgent;

    if(this.userAgent.includes('Android')){
      // window.location.href = this.env.googlePlay;
      window.open(this.env.googlePlay, '_blank');
    }

    else if(this.userAgent.includes('iPhone')){
      window.location.href = this.env.appStore;
      // window.open(this.env.appStore, '_blank');
    }

    else{
      console.log('Desktop');
      // this.router.navigate(['/']);
    }
  }

}
