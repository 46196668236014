<app-header></app-header>
<div class="container">

    <div class="row">
        <div class="col-12 py-5 text-justify">
            <h1><b>Políticas de privacidad</b></h1>
            <br><br>
            La presente Política de Privacidad está destinada para informar al Usuario sobre las prácticas de recopilación, uso y divulgación de la información personal que {{ domain }} puede recopilar sobre el Usuario. {{ domain }} le solicita que previo a compartir e ingresar cualquier tipo de información personal en el Sitio Web {{ domain }} se tome el debido tiempo para leer la presente Política de Privacidad.
            <br><br>
            Toda persona que acceda, utilice y navegue por el Sitio y también toda persona suscrita al presente Sitio (“Usuario”), se encuentra obligado a cumplir y respetar los Términos y Condiciones y estas Políticas de Privacidad. A través del acceso, utilización, navegación y suscripción en el Sitio se  está contratando la utilización de los servicios prestados por {{ domain }} de acuerdo a las cláusulas dispuestas en los Términos y Condiciones y las Políticas de Privacidad, que constituye un contrato entre Usuarios y {{ domain }}. Si no acuerda total o parcialmente sujetarse a los Términos y Condiciones y Políticas de Privacidad, no acceda, utilice, navegue o se suscriba en el Sitio.  
            <br><br>
            <h4><b class="">I. Régimen de Datos Suministrados por los Usuarios</b></h4>
            <br>
            <b>A. Tipo de Información Objeto de Tratamiento en el Sitio</b>
            <br>
            Los Usuarios del Sitio proveen de dos tipos de informaciones a {{ domain }} : 
            <ul style="list-style-type: none">
                <li>(i) datos de suscripción como Usuario.</li>
                <li>(ii) datos de tráfico que se generan los Usuarios al navegar por el Sitio.</li>
            </ul>
            <br>
            <b>B. Datos recopilados de usuarios</b>
            <br>
            El acceso y visualización general de Contenidos en el Sitio, no exige la previa suscripción o registro del Usuario. Si el Usuario desea recibir información adicional y notificación del contenido, se le requerirá su suscripción ingresando nombre, apellido, contraseña y correo electrónico.
            <br><br>
            <b>C. Protección de datos de Suscripción de los Usuario</b>
            <br>
            Al momento de registrarse en el Sitio, el Usuario deberá completar un Formulario de Registración con su información personal. El correo electrónico y la contraseña son privados y no serán publicados abiertamente en el Sitio. El Usuario reconoce que todos los datos suministrados son prestados voluntariamente y que presta su consentimiento a su tratamiento de acuerdo a las presentes Políticas de Privacidad. {{ domain }} utilizará los datos de registro con el fin de cumplir con la prestación de sus servicios requeridos por los Usuarios en el Sitio y para mantener a estos últimos informados de sus actividades, búsquedas y nuevos contenidos del Sitio. En ningún caso serán traspasados a terceros.
            <br><br>
            <b>D. Uso de cookies</b>
            <br>
            Cuando los Usuarios navegan por el Sitio, {{ domain }} recibe información como: tipo de navegador del Usuario, su dirección IP, tiempo de duración de la navegación por el Sitio, página que ha enviado al Usuario al Sitio, tráfico y preferencias del Usuario dentro del Sitio, etc.
            <br><br>
            {{ domain }} utiliza cookies permanentes que se instalan en la PC del Usuario para que el Sitio pueda recordar los nombres de usuario, contraseñas, búsquedas realizadas y otras preferencias elegidas por el mismo.
            <br><br>
            Una cookie es un pequeño fragmento de texto que un sitio web solicita al navegador que guarde en tu ordenador, tablet, smartphone, o incluso cualquier dispositivo que te permita navegar páginas web. Gracias a esas cookies, el sitio web puede “recordar” las acciones o preferencias del Usuario con el paso del tiempo.
            <br><br>
            La utilización de las cookies en el Sitio tienen el exclusivo fin de personalizar la experiencia del Usuario por el Sitio. La instalación de las cookies se realiza en forma automática. Sin embargo, su instalación no es  obligatoria para el Usuario, ya que estos pueden eliminarlas o restringirlas a través de las herramientas que ofrecen sus sistemas operativos.
            <br><br>
            Los Usuarios pueden configurar o modificar los controles de su navegador web para aceptar o rechazar las cookies. Si elige rechazar las cookies, puede seguir utilizando nuestro Sitio, aunque podría provocar algunas limitaciones a sus funciones. Dado que los medios por los que puede rechazar las cookies a través de los controles de su navegador web varían de un navegador a otro, debe visitar el menú de ayuda de su navegador para obtener más información.
            <br><br>
            <b>G. Estadísticas agregadas y servicios a terceros</b>
            <br>
            {{ domain }} utiliza servicios de análisis de terceros como Google Analytics o Google AdSense, donde el titular puede recopilar estadísticas sobre el comportamiento de los visitantes de su sitio web, analizar tendencias, administrar el sitio, estudiar patrones de navegación y recopilar información demográfica. {{ domain }} no se hace responsable del tratamiento de los datos personales que realicen las páginas web a las que pueda acceder a través de los distintos enlaces que contenga el sitio web.
            <br><br>
            <b>F. Seguridad</b>
            <br>
            La seguridad de su información personal es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100 % seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger su información personal, no podemos garantizar su seguridad absoluta. No obstante, para proteger su información personal, tomamos las precauciones razonables y seguimos las mejores prácticas de la industria.
            <br><br>
            <h4><b class="">II.  Obligaciones de los Usuarios</b></h4>
                <br>
                <b>A.  Provisión de Datos Verídicos y Confidencialidad de su Contraseña</b>
                <br>
            Los Usuarios para su efectiva suscripción deben:
            <ul style="list-style-type: none">
                <li>(i) proveer datos verídicos sobre su persona.</li>
                <li>(ii) mantener la seguridad y confidencialidad de su nombre de usuario y contraseña.</li>
            </ul>
            <br><br>
            El carácter de Usuario es personal y no puede ser objeto de cesiones onerosas o gratuitas. Los Usuarios son exclusivamente responsables por la veracidad de los datos suministrados a {{ domain }} y son los únicos responsables por la seguridad y confidencialidad de su contraseña de acceso.
            <br><br>
                    <b>B.    Responsabilidad por los Datos Suministrados</b>
                    <br>
            Los Usuarios son exclusivamente responsables por los datos e informaciones (informaciones, datos, fotografías, etc.)  generados, publicados, transmitidos, compartidos, archivados o facilitados en el Sitio.
            <br><br>
            Los Usuarios no están obligados a generar y publicar sus datos e informaciones. Si los Usuarios eligen suministrar y publicar sus datos e informaciones en cualquier página del Sitio, los mismos autorizan a {{ domain }} a archivar, reproducir, copiar y almacenar los mismos para que estén disponibles en el Sitio y para que se puedan prestar debidamente los servicios a cargo de {{ domain }}
            <br><br>
            El Usuario mantendrá indemne a {{ domain }} y a sus directores, gerentes, representantes, afiliadas y empleados, por todos los reclamos, acciones judiciales o extrajudiciales, denuncias administrativas o demandas que puedan surgir de terceros en relación a la publicación voluntaria que realicen en el Sitio de sus datos e informaciones.
            <br><br>
            {{ domain }} podrá revisar los datos e informaciones, ya sea por su propia iniciativa o en virtud de una denuncia de terceros, y estará facultado a eliminar o retirar sin previo aviso cualquier contenido que a su exclusivo criterio infrinja las Políticas de Privacidad, la ley o las buenas costumbres. 
            <br><br>
            <b>E. Modificación</b>
            <br>
            {{ domain }} se reserva el derecho a modificar total o parcialmente las Políticas de Privacidad, en cualquier momento y sin previo aviso. Si sucede cualquier modificación, se publicará la misma en el Sitio y si el Usuario persiste en la utilización de los servicios prestados, se considerará que ha aceptado implícitamente las nuevas Políticas de Privacidad. Los Usuarios tienen la responsabilidad de visitar regularmente el Sitio para controlar si ha habido cambios a las Políticas de Privacidad de Briyaben.
            <br><br>
            ACEPTACIÓN: Yo, el cliente, aceptó expresamente: a) todos los Términos y Condiciones; y, b) la Política de Privacidad, aquí contenidos.

        </div>
    </div>

</div>
<app-footer></app-footer>