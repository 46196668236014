import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { environment } from 'src/environments/environment';

import { IPStack } from 'src/app/models/ipstack.model';

@Injectable({
  providedIn: 'root'
})
export class IPStackService {

  constructor(
    private http: HttpClient
  ) { }

  async getCurrentLocation(): Promise<google.maps.LatLngLiteral> {
    let data = await this.http.get(`${environment.IPStack.api}/check?access_key=${environment.IPStack.key}`).toPromise();
    let IPStackData = data as IPStack;
    console.log(IPStackData);

    return {
      lat: IPStackData!.latitude != null ? IPStackData!.latitude : 20.096888,
      lng: IPStackData!.longitude != null ? IPStackData!.longitude : -98.764734,
    } as google.maps.LatLngLiteral;
    
  }
}
