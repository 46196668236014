import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shimmer-station',
  templateUrl: './shimmer-station.component.html',
  styleUrls: ['./shimmer-station.component.css']
})
export class ShimmerStationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
