import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { environment } from 'src/environments/environment';
import { Station } from 'src/app/models/station.model';

@Injectable({
  providedIn: 'root'
})
export class StationService {

  constructor(
    private http: HttpClient
  ) { }

  public getStations(
    lat: number,
    lng: number,
    distance: number,
  ): Observable<any>{
    return this.http.get(`${environment.gasMexApi}/places/${lat}/${lng}/${distance}`);
  }

  public getStation(idStation: number): Observable<any>{
    return this.http.get(
      `${environment.functions.urlApi}/stations/${idStation}`
    );
  }

  public getLatLng(station: Station): google.maps.LatLngLiteral {
    return {
      lat: station.latitude,
      lng: station.longitude
    }
  }

  public getMarkerOptions(station: Station): google.maps.MarkerOptions {
    return{
      draggable: false,
      title: station.name
    }
  }
}
